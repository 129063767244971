import type * as maps from '@contentya/maps-interface';
import type * as ml from 'maplibre-gl';
import { mlLngLat } from './coords';
import { mlPoint } from './point';

type MlAnimationOptions<T extends maps.AnimationOptions> = Omit<T, 'offset'> & ml.AnimationOptions;

export function mlAnimationOptions<T extends maps.AnimationOptions>(options: T): MlAnimationOptions<T> {
    const { offset, ...rest } = options;
    const adjusted: MlAnimationOptions<T> = rest;

    if (offset !== undefined) {
        adjusted.offset = mlPoint(offset);
    }

    return adjusted;
}

type MlCameraOptions<T extends maps.CameraOptions> = MlCenterZoomBearing<Omit<T, 'around' | 'tilt'>> & ml.CameraOptions;

export function mlCameraOptions<T extends maps.CameraOptions>(options: T): MlCameraOptions<T> {
    const { around, tilt, ...rest } = options;
    const adjusted: MlCameraOptions<T> = mlCenterZoomBearing(rest);

    if (around !== undefined) {
        adjusted.around = mlLngLat(around);
    }

    if (tilt != null && !('pitch' in adjusted)) {
        adjusted.pitch = tilt;
    }

    return adjusted;
}

type MlCenterZoomBearing<T extends maps.CenterZoomBearing> = Omit<T, 'center' | 'heading'> & ml.CenterZoomBearing;

export function mlCenterZoomBearing<T extends maps.CenterZoomBearing>(options: T): MlCenterZoomBearing<T> {
    const { center, heading, ...rest } = options;
    const adjusted: MlCenterZoomBearing<T> = rest;

    if (center !== undefined) {
        adjusted.center = mlLngLat(center);
    }

    if (heading != null && !('bearing' in adjusted)) {
        adjusted.bearing = heading;
    }

    return adjusted;
}

type MlFlyToOptions<T extends maps.FlyToOptions> = MlAnimationOptions<MlCameraOptions<T>> & ml.FlyToOptions;

export function mlFlyToOptions<T extends maps.FlyToOptions>(options: T): MlFlyToOptions<T> {
    return mlAnimationOptions(mlCameraOptions(options));
}

//type LocalCenterZoomBearing = {
//    center?: LngLatLike;
//};
//
//type LocalCameraOptions = LocalCenterZoomBearing & {
//    around?: LngLatLike;
//};
//
//type LocalFitBoundsOptions = LocalFlyToOptions;

//export type CameraUpdateTransformFunction = (next: {
//    center: ml.LngLat;
//    zoom: number;
//    pitch: number;
//    bearing: number;
//    elevation: number;
//}) => {
//    center?: LngLatLike;
//    zoom?: number;
//    pitch?: number;
//    bearing?: number;
//    elevation?: number;
//};
//
//export function mlFitBoundsOptions(options: FitBoundsOptions): ml.FitBoundsOptions {
//    return mlCameraOptions(options);
//}
//
//export function mlCameraUpdateTransformFunction(func: CameraUpdateTransformFunction): ml.CameraUpdateTransformFunction {
//    return (next) => {
//        const { center, ...rest } = func(next);
//        if (center === undefined) {
//            return rest;
//        }
//        return { center: mlLngLat(center), ...rest };
//    };
//}
