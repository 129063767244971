import * as ml from 'maplibre-gl';
import type { LngLatLike } from '@contentya/lnglat-coords';
import { lngLatTupleImpl } from '@contentya/lnglat-coords';

export function compatLngLatLike(coords: ml.LngLatLike): LngLatLike {
    return coords;
}

/**
 * Converts {@link LngLatLike} to Maplibre's
 * {@link LngLat | https://maplibre.org/maplibre-gl-js-docs/api/geography/#lnglat}
 */
export function mlLngLat(coords: LngLatLike): ml.LngLat {
    if (coords instanceof ml.LngLat) {
        return coords;
    }
    return new ml.LngLat(...lngLatTupleImpl('mlLngLat()', coords));
}
