import type * as maps from '@contentya/maps-interface';
import type { Map } from './map';
import type { Marker } from './marker';
import { map } from './map';
import { marker } from './marker';

export class Library implements maps.Library {
    map(container: string | HTMLElement, options?: maps.MapOptions): Map {
        return map(container, options);
    }

    marker(options?: maps.MarkerOptions): Marker {
        return marker(options);
    }
}

export function library(): Library {
    return new Library();
}
