import type * as ml from 'maplibre-gl';
import type * as maps from '@contentya/maps-interface';
// import type {
//     FitBoundsOptions,
//     CameraUpdateTransformFunction,
// } from './cameraoptions';
// import type { LngLatBoundsLike } from '@contentya/lnglat-bounds';
// import type { LngLatLike } from '@contentya/lnglat-coords';
// import {
//     mlFitBoundsOptions,
//     mlCameraUpdateTransformFunction,
// } from './cameraoptions';
import { mlLngLat } from './coords';
import { mlLngLatBounds } from './bounds';

export function mlDefaultStyle(): ml.StyleSpecification {
    return {
        version: 8,
        sources: {
            osm: {
                type: 'raster',
                tiles: ['https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'],
                tileSize: 256,
                attribution: '&copy; OpenStreetMap Contributors',
                maxzoom: 19,
            },
        },
        layers: [
            {
                id: 'osm',
                type: 'raster',
                source: 'osm',
            },
        ],
    };
}

type MlMapOptions<T extends maps.MapOptions> = Omit<
    T,
    'bounds' | 'center' | 'doubleClickZoom' | 'heading' | 'maxBounds' | 'tilt' | 'zoom'
> &
    ml.MapOptions;

export function mlMapOptions<T extends maps.MapOptions>(container: HTMLElement | string, options: T): MlMapOptions<T> {
    const { bounds, center, doubleClickZoom, heading, maxBounds, tilt, zoom, ...rest } = options;
    const adjusted: MlMapOptions<T> = { style: mlDefaultStyle(), ...rest, container };

    if (bounds !== undefined) {
        adjusted.bounds = mlLngLatBounds(bounds);
    }

    if (center != null) {
        adjusted.center = mlLngLat(center);
    }

    if (doubleClickZoom !== undefined) {
        adjusted.doubleClickZoom = doubleClickZoom === 'center' || doubleClickZoom;
    }

    if (heading != null && !('bearing' in adjusted)) {
        adjusted.bearing = heading;
    }

    if (maxBounds !== undefined) {
        adjusted.maxBounds = mlLngLatBounds(maxBounds);
    }

    if (tilt != null && !('pitch' in adjusted)) {
        adjusted.pitch = tilt;
    }

    if (zoom != null) {
        adjusted.zoom = zoom;
    }

    // if (fitBoundsOptions !== undefined) {
    //     adjusted.fitBoundsOptions = mlFitBoundsOptions(fitBoundsOptions);
    // }

    //    if (transformCameraUpdate !== undefined) {
    //        adjusted.transformCameraUpdate = mlCameraUpdateTransformFunction(
    //            transformCameraUpdate
    //        );
    //    }

    return adjusted;
}
