import * as ml from 'maplibre-gl';
import type { LngLatBoundsLike } from '@contentya/lnglat-bounds';
import { lngLatBoundsPlainImpl } from '@contentya/lnglat-bounds';

export function mlLngLatBounds(bounds: LngLatBoundsLike): ml.LngLatBounds;
export function mlLngLatBounds(bounds: unknown): ml.LngLatBounds {
    if (bounds instanceof ml.LngLatBounds) {
        return bounds;
    }

    return new ml.LngLatBounds(lngLatBoundsPlainImpl('mlLngLatBounds()', bounds));
}

export function compatLngLatBoundsLike(bounds: ml.LngLatBoundsLike): LngLatBoundsLike {
    return bounds;
}
