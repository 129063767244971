(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("maplibregl"));
	else if(typeof define === 'function' && define.amd)
		define(["maplibregl"], factory);
	else if(typeof exports === 'object')
		exports["contentya"] = factory(require("maplibregl"));
	else
		root["contentya"] = root["contentya"] || {}, root["contentya"]["maplibregl"] = factory(root["maplibregl"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__229__) => {
return 