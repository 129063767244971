import type * as maps from '@contentya/maps-interface';
import type * as ml from 'maplibre-gl';
import { mlPoint } from './point';

type MlMarkerOptions<T extends maps.MarkerOptions> = Omit<T, 'offset'> & ml.MarkerOptions;

export function mlMarkerOptions<T extends maps.MarkerOptions>(options: T): MlMarkerOptions<T> {
    const { offset, ...rest } = options;

    const adjusted: MlMarkerOptions<T> = rest;

    if (typeof offset !== 'undefined') {
        adjusted.offset = mlPoint(offset);
    }

    return adjusted;
}
