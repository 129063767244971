import type * as ml from 'maplibre-gl';
import Point from '@mapbox/point-geometry';
import type { PointLike } from '@contentya/maps-point';
import { pointTupleImpl } from '@contentya/maps-point';

/**
 * Converts {@link PointLike} to maplibre compatible
 * {@link https://github.com/mapbox/point-geometry | Point}.
 */
export function mlPoint(point: PointLike): Point {
    if (point instanceof Point) {
        return point;
    }
    return new Point(...pointTupleImpl('mlPoint()', point));
}

export function compatPointLike(point: ml.PointLike): PointLike {
    return point;
}
