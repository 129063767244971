import * as ml from 'maplibre-gl';
import type * as maps from '@contentya/maps-interface';
import type { LngLatLike, LngLatLiteral } from '@contentya/lnglat-coords';
import { mlLngLat } from './coords';
import { mlMapOptions } from './mapoptions';
import { mlFlyToOptions, mlAnimationOptions } from './cameraoptions';

export class Map implements maps.MapAdapter<ml.Map> {
    private readonly _map: ml.Map;

    constructor(map: ml.Map) {
        this._map = map;
    }

    //
    // Compat API
    //

    adaptee(): ml.Map {
        return this._map;
    }

    flyTo(options: maps.FlyToOptions, eventData?: unknown): this {
        if (typeof eventData === 'undefined') {
            this._map.flyTo(mlFlyToOptions(options));
        } else {
            this._map.flyTo(mlFlyToOptions(options), eventData);
        }
        return this;
    }

    getCenter(): LngLatLiteral {
        return this._map.getCenter();
    }

    getContainer(): HTMLElement {
        return this._map.getContainer();
    }

    getHeading(): number {
        return this._map.getBearing();
    }

    getMaxZoom(): number {
        return this._map.getMaxZoom();
    }

    getMinZoom(): number {
        return this._map.getMinZoom();
    }

    getTilt(): number {
        return this._map.getPitch();
    }

    getZoom(): number {
        return this._map.getZoom();
    }

    panTo(coords: LngLatLike, options?: maps.PanOptions): this {
        if (typeof options === 'undefined') {
            this._map.panTo(mlLngLat(coords));
        } else {
            this._map.panTo(mlLngLat(coords), mlAnimationOptions(options));
        }
        return this;
    }

    setCenter(center: LngLatLike): this {
        this._map.setCenter(mlLngLat(center));
        return this;
    }

    setHeading(heading: number, eventData?: unknown): this {
        if (eventData === undefined) {
            this._map.setBearing(heading);
        } else {
            this._map.setBearing(heading, eventData);
        }
        return this;
    }

    setMaxZoom(maxZoom?: number | null | undefined): this {
        this._map.setMaxZoom(maxZoom ?? undefined);
        return this;
    }

    setMinZoom(minZoom?: number | null | undefined): this {
        this._map.setMinZoom(minZoom ?? undefined);
        return this;
    }

    setTilt(tilt: number, eventData?: unknown): this {
        if (eventData === undefined) {
            this._map.setPitch(tilt);
        } else {
            this._map.setPitch(tilt, eventData);
        }
        return this;
    }

    setZoom(zoom: number): this {
        this._map.setZoom(zoom);
        return this;
    }

    //
    // Maplibre GL - specific API
    //
}

export function map(container: HTMLElement | string, options: maps.MapOptions = {}) {
    return new Map(new ml.Map(mlMapOptions(container, options)));
}
