import * as ml from 'maplibre-gl';
import type * as maps from '@contentya/maps-interface';
import type { LngLatLike } from '@contentya/lnglat-coords';
import { Map } from './map';
import { mlLngLat } from './coords';
import { mlMarkerOptions } from './markeroptions';

export class Marker implements maps.MarkerAdapter<ml.Marker> {
    _marker: ml.Marker;

    constructor(marker: ml.Marker) {
        this._marker = marker;
    }

    adaptee(): ml.Marker {
        return this._marker;
    }

    setPosition(position: LngLatLike | null): this {
        this._marker.setLngLat(mlMarkerPosition(position));
        return this;
    }

    getPosition(): ml.LngLat {
        return this._marker.getLngLat();
    }

    addToMap(map: maps.Map): this {
        if (map instanceof Map) {
            this._marker.addTo(map.adaptee());
        }
        return this;
    }

    removeFromMap(): this {
        this._marker.remove();
        return this;
    }
}

export function marker(options?: maps.MarkerOptions): Marker {
    if (typeof options === 'undefined') {
        return new Marker(new ml.Marker());
    }

    const { map, position } = options;
    const mlOpts = mlMarkerOptions(options);
    const marker = new Marker(new ml.Marker(mlOpts));

    //
    // Important: the order must be: fist setPosition() then addToMap()
    //

    if (position != null) {
        marker.setPosition(position);
    }

    if (map != null) {
        marker.addToMap(map);
    }

    return marker;
}

export function mlMarkerPosition(position?: LngLatLike | null | undefined): ml.LngLatLike {
    if (position == null) {
        return new ml.LngLat(0, 0);
    }
    return mlLngLat(position);
}
