/* istanbul ignore file */

(() => {
    if (!('maplibregl' in global)) {
        const u = 'https://github.com/maplibre/maplibre-gl-js#getting-started';
        console.log(`maplibre-gl not loaded yet, see ${u} for more info`);
    }
})();

export * from '.';
